.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.emailAdded{

  font-weight: bold;
  width: 70%;
  padding: 10px;
  font-size: 16px;
  cursor:pointer;
  border:none;
  color:darkgrey;

}

.emailAdded:hover{

 font-size: 120%;
color:darkred
}



.firmatariInfo{

  font-weight: bold;
  width: 70%;

  font-size: 14px;
  cursor:pointer;
  border:none;
  color:darkgrey;

}

.firmatariInfo:hover{
  color:#282c34;
}
.firmatariInfoLeft{

  font-weight: bold;
  width: 70%;

  font-size: 14px;
  cursor:pointer;
  border:none;
  color:darkred;

}
.firmatariInfoLeft:hover{
  color:#f24f27
}

#inputEmail:hover{

  border:2px solid orange
}
#inputEmail:focus{

  border:2px solid orange
}
.statsHeader{
  color:#282c34;
  width: 100%;
  cursor: pointer;
}
.statsHeader:hover{
  font-weight: bold;
}

#inputEmail{

  border:2px solid lightgrey
}


.elementTab:hover{

  background-color: #282c34;
  color:white;

}
.elementTab{

  color: #06c;
  width: 100%;
  cursor: pointer;
  padding :10px 10px 10px 10px;
  border-radius: 10px 100px / 120px;
}

.elementTitle:hover{

  color: #282c34;

}
.elementTitle{
  color: darkgrey;
  width: 100%;
  cursor: pointer;
  padding :10px 10px 10px 10px;

}


#emailList::-webkit-scrollbar {
  width: 5px;
}

/* Traccia */
#emailList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Maniglia */
#emailList::-webkit-scrollbar-thumb {
  background: #888;
}

/* Maniglia al passaggio del mouse */
#emailList::-webkit-scrollbar-thumb:hover {
  background: #555;
}


#textDescr:hover{

  border:2px solid orange;

}
#textDescr:focus{

  border:2px solid orange;

}
#textDescr{

  border:2px solid lightgrey
}

#richDescr:hover{

  border:2px solid orange
}
#richDescr:focus{

  border:2px solid orange
}
#richDescr{

  border:2px solid white;
  width: 61%;
  padding: 10px;
  margin-left:50px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align:left;
  border:2px solid lightgrey;
}

.linkNav:hover{
  font-weight:bold;

}

.select-css {
  font-size: 16px;
  font-family: sans-serif;
  border-radius: 15px;
  border: white;

}


.spinner {
  margin: 100px auto;
  width: 270px;
  height: 270px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 13.0s infinite linear;
  animation: sk-rotate 13.0s infinite linear;
}

.spinner img {
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  background-color: #06c;
  padding:3%;
  border-radius:50%;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

#mydiv {
  font-size: 24px;
  color:#06c;
  cursor:pointer;
}

#mymodal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  padding-left:47%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

#mymodal-content {
  margin: auto;
  display: block;
  width: 89%;
  height:61%;
}

#mydiv:hover + #mymodal {
  display: block;
}

#mymodal:hover {
  display: none;
}

#divEtichetta{
  width: 100%;
  padding: 10px;
  overflow: auto;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:black;

  border:3px solid grey;


}
#divEditor {
  max-width:70%;
  width:40%;
  resize: both;
  overflow: auto;
  min-width:30%;
  min-height:293px;
  height:100%;
}
#ingredientsList {
  order: 1;
  align-self: flex-start;
}
#preservativesList {
  order: 2;
  align-self: flex-start;
}
#contieneList {
  order: 3;
  align-self: flex-start;
}

#tblNutrition {
  order: 4;
  margin-top: auto;
  padding-left:20%;
  margin-bottom: auto;
  min-height: 20%;
  min-width:40%;
  font-size:0.65em;
  cursor:pointer;

}
#bottledEditor {
  order: 7;
  margin-top: auto;
  margin-bottom: auto;
  min-height: 20%;
  min-width:40%;
  font-size:0.65em;
  cursor:pointer;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
table, th {
  border-bottom: 1px solid black;
  border-collapse: collapse;
  font-size: 13px;
}

#tblNutrition th, td {
  padding: 3px;
  text-align: left;
  order: 1;
}
#bottleTable th, td {
  padding: 3px;
  text-align: center;
  font-size: 13px;
}
#labelSection {
  order: 6;
}

.itemEtList{

  cursor:pointer;

}

.etDashList{
  height: 150px;
  width: 150px;
  border: 1px solid lightgrey;
  margin: 3.14%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
}
.etDashList:first-child {
  height: 150px;
  width: 150px;
  border: 3px solid orange;
  margin: 3.14%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
}
.itemEtList:hover{

  font-weight: bold;

}

.etList:hover{

}

.navSelLang{

  background-color: #87ceeb;

}

.switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch--toggled {
  background-color: #4caf50;
}

.switch__circle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s ease;
}

.switch--toggled .switch__circle {
  left: 26px;
}


.modern-list {
  list-style-type: none;
  padding: 0;
  border: 1px solid #06c;
  overflow: auto;
  max-height:180px;
  border-radius: 3.14px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Traccia della barra di scorrimento */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Maniglia della barra di scorrimento */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3.14px;
}

/* Maniglia della barra di scorrimento al passaggio del mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.list-item:hover {

  background-color:#06c;
  color:white

}
.list-item {
  cursor: pointer;
  padding:3%;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  font-size: 17px;


}

.list-item:hover {
  font-weight: bold;


}

.qrPar{
  cursor:pointer;
  order: 4;
  margin-top: auto;
  padding: 10%;
  margin-bottom: auto;
  min-height: 20%;
  min-width: 40%;
  border:0px solid #000000;
  border-collapse: collapse;
}

.navbar{
  padding:0px 0px;

}
.mainCont{
  display:flex;
  justify-content: space-around;
  width:100%;
}

#inputComponents{
  min-width: 30%;
  max-width:60%;
  width:60%;
}

.userNav{
  font-size: 70px;
 cursor:pointer;
}

.userNavMobile{
 font-size: 1px;
}
.dropdown-center {
  position: relative;
}

.dropdown-center .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}

.removeSpan{
  left: 120%;
  transform: translateX(-120%);
}

.dashboardContainerView {
  flex: 1;
  display: flex;
  flex-wrap: wrap;


}


/* Stili per evidenziare gli allergeni */
.itemEtList strong {
  font-weight: bold;
}
.list-item strong {
  font-weight: bold;
}

/* stili per rimuovere le frecce dall'input number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Stili per il messaggio di accettazione in crea/modifica etichetta */
.alert-success {
  font-weight: bold;
}


/* Stile per schermi con larghezza massima di 600px (tipicamente smartphone) */
@media only screen and (max-width: 600px) {
  #divEditor {
    max-width:100%;
    width:100%;
    resize: both;
    overflow: auto;
    min-width:30%;
    min-height:390px;
    height:100%;
  }
  #inputComponents{
    max-width:100%;
    width:100%;
    padding-right:0%;

  }
.mainCont{
  display:block;
 justify-content: space-around;
 width:100%;
}

  #changeLangView{
    margin-right:0;
    padding:0px;
  }
  .navbar{
    padding:0px 0px;
    justify-content: right;

  }

  .mainContainerView{
    min-height: 433px;
    width:93%;
    padding:3.14%;
    border-left: 1px solid lightgrey;
    padding-top:13%;
    padding-bottom:13%;

  }


}

/* Stile per schermi con larghezza minima di 601px e massima di 1024px (tipicamente tablet) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  #divEditor {
    max-width:70%;
    width:70%;
    resize: both;
    overflow: auto;
    min-width:30%;
    min-height:390px;
    height:100%;
  }

  #changeLangView{
    margin-right:20%;
    padding:0px;
  }
  .navbar{
    padding:0px 0px;

  }
  #inputComponents{
    min-width:30%;
    max-width:60%;
    width:60%;
    padding-right:7%;

  }

  .mainContainerView{
    min-height: 587px;
    width:93%;
    padding:3.14%;
    border-left: 1px solid lightgrey;
    padding-top:17%;
    padding-bottom:17%;

  }
}



/* Stile per schermi con larghezza minima di 1025px (tipicamente desktop) */
@media only screen and (min-width: 1025px) {
  #divEditor {
    max-width:70%;
    width:40%;
    resize: both;
    overflow: auto;
    min-width:30%;
    min-height:390px;
    height:100%;
  }
  #changeLangView{
    margin-right:30%;
  }
  .navbar{
    padding:0px 0px;

  }
  #inputComponents{
    min-width:30%;
    max-width:60%;
    width:60%;
    padding-right:7%;

  }

  .mainContainerView{
    min-height: 251px;
    width:93%;
    padding:3.14%;
    border-left: 1px solid lightgrey;

  }
}


.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.modal-content {
  background-color: #fff;
  max-width: 600px;
  margin: 20px auto;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.close-button {
  float: right;
  cursor: pointer;
}

.it-brand-text a {
  color: white;
}

.it-brand-text a:hover {
  color: white;
}